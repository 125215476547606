import {useMemo} from 'react';

import {homeRouteByRole, menuItemsByRole} from '@config/UserRolesConfig';
import {LoggedUserInfo} from '@medad-sep/core';
import {useCurrentUser} from '@shared/hooks/ui/useCurrentUser';
import {MenuItem} from '@shared/models/MenuItem';

export function useCurrentUserConfig(): {homeRoute: string; menuItems: MenuItem[]} {
  const userProfile = useCurrentUser();

  const getRole = (userProfile: LoggedUserInfo) => userProfile.roles?.find((role) => !!homeRouteByRole[role]);

  const menuItems = useMemo(() => {
    if (userProfile) {
      const role = getRole(userProfile);
      return menuItemsByRole[role || 'admin'];
    } else {
      return menuItemsByRole.admin;
    }
  }, [userProfile]);

  const homeRoute = useMemo(() => {
    if (userProfile) {
      const role = getRole(userProfile);
      return homeRouteByRole[role || 'admin'];
    } else {
      return homeRouteByRole.admin;
    }
  }, [userProfile]);

  return {menuItems, homeRoute};
}
