import React, {useEffect} from 'react';
import {RouteProps, useHistory} from 'react-router';
import {Route, RouteComponentProps} from 'react-router-dom';

import {useAuth, useIsAuthenticated} from '@medad-sep/core';

interface PrivateRouteParams extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export function PrivateRoute(props: PrivateRouteParams) {
  const isAuthenticated = useIsAuthenticated();
  const {logout} = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      logout().then(() => {
        history.push({pathname: '/login', state: {from: location.pathname}});
      });
    }
  }, [isAuthenticated]);

  return isAuthenticated ? <Route {...props} /> : null;
}
