import {useQuery} from 'react-query';

import {LoggedUserInfo, useAuth} from '@medad-sep/core';
import {useKy} from '@shared/services/api';

export function useCurrentUser() {
  const KyInstance = useKy();
  const {discovery} = useAuth();

  const res = useQuery(['userinfo'], () =>
    KyInstance.get('', {prefixUrl: discovery?.userInfoEndpoint}).json<LoggedUserInfo>(),
  );

  return res.data;
}
