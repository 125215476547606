import {ApiConfig, EnvironmentProfile} from '@medad-sep/core';

const configOverride = (window as any)?.configOverride ?? {ssoConfigs: {}};
const ssoConfigsOverride = configOverride.ssoConfigs ?? {};

// todo: type configOverride
if (configOverride.baseApiUrl) {
  configOverride.designerBaseApiUrl = configOverride.baseApiUrl;
}

const baseApiUrl = 'https://api-sep.medadstg.com';
const ssoUrl = 'https://sso.medadstg.com/realms/medad';

export const config = {
  baseApiUrl: baseApiUrl,
  designerBaseApiUrl: baseApiUrl,
  defaultAppLang: 'en',
  tenant: 'medad',
  ...configOverride,
  ssoConfigs: {
    url: ssoConfigsOverride.url || ssoUrl,
    clientId: 'sep-client',
    ...ssoConfigsOverride,
  },
};

if (!config.designerBaseApiUrl) {
  config.designerBaseApiUrl = config.baseApiUrl;
}

export const AppApiConfig: ApiConfig = {
  baseApiUrl: config.designerBaseApiUrl,
  tenant: config.tenant,
  environmentProfile: EnvironmentProfile.DRAFT,
};
