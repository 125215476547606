import React from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import {XStack, Button, YStack, View, Tooltip, Typography} from '@medad-sep/core';
import {MenuItem} from '@shared/models/MenuItem';

interface Props {
  menuItems: MenuItem[];
}

export function AppSideBar({menuItems}: Props): JSX.Element {
  const location = useLocation();

  return (
    <XStack
      paddingBottom="$4"
      width="$48"
      paddingVertical="$0"
      backgroundColor="$accent"
      justifyContent="space-between"
      alignItems="flex-start"
      borderBottomColor="$border"
      borderBottomWidth="$0.5"
      height="unset">
      <YStack width="100%" justifyContent="center">
        {menuItems?.map(({route, label, icon}) => {
          return (
            <View
              height="calc(5vh - 1px)"
              key={route}
              alignItems="center"
              justifyContent="center"
              backgroundColor={location.pathname === route ? '$lightWhite' : '$transparent'}>
              <Tooltip allowFlip placement="left-start" content={<Typography>{label}</Typography>}>
                <Link to={route} style={{textDecoration: 'none'}}>
                  <Button variant="empty" icon={icon} color="white" size={24} />
                </Link>
              </Tooltip>
            </View>
          );
        })}
      </YStack>
    </XStack>
  );
}
