class CacheService {
  get<T>(key): T {
    const value = localStorage.getItem(key);
    return value != null ? JSON.parse(value) : null;
  }

  set(key, value): void {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key): void {
    return localStorage.removeItem(key);
  }

  clear(): void {
    return localStorage.clear();
  }
}

export const cacheService = new CacheService();
