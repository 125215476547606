import React from 'react';

import {ConfigProvider} from 'antd';

import {useUIConfig} from '@shared/hooks/ui/useUIConfig';

interface Props {
  children: any;
}

export function UIConfigProvider({children}: Props) {
  const {antDesignLocal, dir, themeTokens} = useUIConfig();

  return (
    <ConfigProvider locale={antDesignLocal} direction={dir} theme={{token: themeTokens}}>
      {children}
    </ConfigProvider>
  );
}
