import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {config} from '@config';

import {AuthProvider} from '@medad-sep/core';
import {AppSection} from '@shared/components/AppSection';
import {PrivateRoute} from '@shared/components/Auth/PrivateRoute';
import {UIConfigProvider} from '@shared/components/UIConfigProvider';

const Login = React.lazy(() => import('./modules/Auth/routes/Login'));
const Admin = React.lazy(() => import('./modules/Admin'));
const AppPreview = React.lazy(() => import('./modules/AppPreview'));

const Playground = React.lazy(() => import('./modules/Playground'));

function App(): JSX.Element {
  return (
    <Switch>
      <Route path="/app-preview" component={AppPreview} />
      <AuthProvider config={config.ssoConfigs}>
        {/* TODO: remove this when we get rid of all antd components */}
        <UIConfigProvider>
          <Switch>
            <Redirect from="/" to="admin/designer" exact />
            <Route path="/login" component={Login} />
            <Route path="/playground" component={Playground} />
            <AppSection>
              <Switch>
                <PrivateRoute path="/admin" component={Admin} />
              </Switch>
            </AppSection>
          </Switch>
        </UIConfigProvider>
      </AuthProvider>
    </Switch>
  );
}

export default App;
