import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {Avatar, IconView, ListItem, Select, Typography, YStack, useAppDirection, useAuth} from '@medad-sep/core';

export function UserInfo(): JSX.Element {
  const {direction} = useAppDirection();
  const {t} = useTranslation();
  const {logout} = useAuth();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  return (
    <Select open={isSelectOpen} onOpenChange={setIsSelectOpen} trigger={<Avatar marginHorizontal={1} size="sm" />}>
      <YStack>
        <ListItem
          onPress={logout}
          prefix={<IconView icon="LogOut" size={15} />}
          minWidth="$19"
          direction={direction}
          title={
            <Typography marginHorizontal="$2" fontWeight="500">
              {t('logout')}
            </Typography>
          }
        />
      </YStack>
    </Select>
  );
}
