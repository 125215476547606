import {Local} from './Local';

export const en: Local = {
  translation: {
    language: {
      en: 'English',
      ar: 'العربية',
    },
    validation: {
      requiredField: 'Required field',
      mustBePositive: 'Must be Positive',
    },
    environments: {
      PRE_PRODUCTION: 'Pre Production',
      PRODUCTION: 'Production',
    },
    general: {
      enable: 'Enable',
      enableAll: 'Enable All',
      homePage: 'Set as Homepage',
      home: 'Home',
    },
    notifications: 'Notifications',
    tags: 'Tags',
    clearFilters: 'Clear Filters',
    filter: 'Filter',
    sort: 'Sort',
    login: 'Login',
    logout: 'Logout',
    generalErrorMsg: 'Sorry, something went wrong.',
    successMsg: 'Done, Successfully',
    actions: 'Actions',
    action: {
      new: 'New',
      edit: 'Edit',
      delete: 'Delete',
      add: 'Add',
      save: 'Save',
      undo: 'Undo',
      redo: 'Redo',
      apply: 'Publish',
      cancel: 'Cancel',
      preview: 'Preview',
      set: 'set',
    },
    dashboard: 'Dashboard',
    designer: 'Designer',
    configurations: 'Configurations',
    services: 'E-Services',
    configurationsOf: 'Configurations Of',
    properties: 'Properties',
    layers: 'Layers',
    settings: {
      settings: 'Settings',
      pageSettings: 'Page Settings',
      pageTitle: 'Page title',
      pageIcon: 'Page icon',
      addWidget: 'Add Widget',
      clickToEdit: 'Click on a component to start editing.',
      insertRow: 'Insert new row',
      spacing: 'Spacing',
      measures: 'Measures',
      alignment: 'Alignment',
      noSectionsMsgTitle: 'Create a section first',
      noSectionsMsg:
        'To start editing you need first to create a section, Hover over the editor you well see a (+) button , hit the button and you are good to go or from the below button',
      layout: {
        label: 'Layout',
        width: 'Width',
        minWidth: 'Min Width',
        maxWidth: 'Max Width',
        height: 'Height',
        minHeight: 'Min Height',
        maxHeight: 'Max Height',
        margin: 'Margin',
        padding: 'Padding',
        resizeMode: 'Resize Mode',
        contain: 'Contain',
        cover: 'Cover',
        stretch: 'Stretch',
        repeat: 'Repeat',
        center: 'Center',
        left: 'Left',
        right: 'Right',
        top: 'Top',
        bottom: 'Bottom',
        gap: 'gap',
      },
      style: {
        label: 'Style',
        opacity: 'Opacity',
        color: 'Color',
        background: 'Background',
        backgroundColor: 'Background color',
      },
      typography: {
        label: 'Typography',
        initialText: 'Your content goes here. Edit styles and content or remove this text From the side bar',
        fontSize: 'Size',
        fontWeight: 'Weight',
        textAlign: 'Align',
        start: 'Start',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        thin: 'Thin',
        normal: 'Normal',
        bold: 'Bold',
        lineHeight: 'line Height',
      },
      basic: {
        label: 'Basic',
        text: 'Text',
        url: 'URL',
      },
      button: {
        empty: 'empty',
        filled: 'filled',
        outlined: 'outlined',
        danger: 'danger',
        info: 'info',
        neutral: 'neutral',
        primary: 'primary',
        success: 'success',
        warning: 'warning',
      },
      accordion: {
        newSection: 'New Section',
      },
      borders: {
        label: 'Borders',
        borderWidth: 'Border width',
        borderRadius: 'Border Radius',
        borderColor: 'Border Color',
        width: 'width',
        color: 'Color',
        radius: 'Radius',
      },
      flex: {
        label: 'Flex',
        direction: 'Direction',
        wrap: 'Wrap',
        align: 'Align',
        alignSelf: 'Align Self',
        justify: 'Justify',
        row: 'Row',
        column: 'Column',
        nowrap: 'No Wrap',
        wrapReverse: 'Wrap Reverse',
        flexStart: 'Start',
        flexEnd: 'End',
        center: 'Center',
        stretch: 'Stretch',
        spaceEvenly: 'Space Evenly',
        spaceBetween: 'Space Between',
        spaceAround: 'Space Around',
        grow: 'Grow',
      },
    },
    viewportSizes: {
      mobile: 'Mobile',
      tablet: 'Tablet',
      desktop: 'Desktop',
    },
    appConfig: {
      title: 'App',
      primaryColor: 'Primary Color',
      accentColor: 'Accent Color',
      logo: 'Logo',
      icon: 'Icon',
      icons: 'Icons',
      splashScreen: 'Splash Screen',
      brandColors: 'Brand Colors',
      generalSettings: 'General settings',
    },
    deploySettings: {
      title: 'Deploy Settings',
      buildApp: 'Build App',
      credentials: 'Credentials',
      buildHistory: 'Build History',
      testConnection: 'Test Connection',
      username: 'Username',
      password: 'Password',
      EASCredentials: 'EAS Credentials',
      track: 'Track',
      enable: 'Enable',
      saveCredentials: 'Save Credentials',
      newBuildsOccurMessage: 'New builds occur weekly. The maximum number of builds depends on your EAS account plan.',
      somethingWentWrong: 'Something went wrong',
      easConnectedSuccessfully: 'EAS Connected successfully',
      doneSuccessfully: 'Done Successfully',
      createdOn: 'Created On',
      usernameOrPasswordIncorrect: 'Username or password is incorrect',
      easToken: 'EAS Token',
    },
    homeConfig: {
      title: 'Components Customizations',
      enable: 'Enable/Disable',
    },
    appletConfig: {
      enable: 'Enable/Disable',
      componentsCustomizations: 'Components Customizations',
      pleaseEnableConnector: 'To view available services, please enable this connector',
      general: 'General',
    },
    servicesAuthorization: {
      title: 'Services Authorization',
    },
    generalConfig: {
      title: 'General',
    },
    public: 'Public',
    private: 'Private',
    elements: 'Elements',
    widgets: 'Widgets',
    content: 'Content',
    header: 'header',
    design: 'Design',
    variant: 'Variant',
    type: 'Type',
    pages: 'Pages',
    section: 'Section',
    container: 'Container',
    addElement: 'Add Element',
    changeIcon: 'Change Icon',
    selectRequiredRoles: 'Select Required Roles',
    servicePage: 'Service Page',
    contentPage: 'Content Page',
    selectService: 'Select Service',
    launcher: 'Launcher',
    launcherTop: 'Launcher Top',
    launcherBottom: 'Launcher Bottom',
    auxiliaryPages: 'Auxiliary Pages',
    auxiliaryPage: 'Auxiliary Page',
    pagesGroup: 'Pages Group',
    upload: 'Upload',
    others: 'Others',
    sessions: {
      title: 'Sessions',
      enable: 'Enabled',
      disable: 'Disabled',
      setSession: 'Set Session Time Limit',
    },
    home: 'Home',
    highlighted: 'Highlighted',
  },
};
