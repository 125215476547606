import {useEffect, useRef, useState} from 'react';

import {theme as AntdTheme} from 'antd';
import {DirectionType} from 'antd/lib/config-provider';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

import {defaultTheme} from '@theme/default';

import('dayjs/plugin/localeData').then((m) => dayjs.extend(m.default));
import('dayjs/plugin/utc').then((m) => dayjs.extend(m.default));

export function useUIConfig() {
  const {i18n} = useTranslation();
  const [dir, setDir] = useState<DirectionType>();
  const [antDesignLocal, setAntDesignLocal] = useState<any>();

  const currentLang = useRef<string>();

  const setThemeVariables = () => {
    // add css variables copy of theme tokens to page
    const cssVariables = Object.entries(AntdTheme.defaultAlgorithm({...AntdTheme.defaultSeed, ...defaultTheme}))
      .map(([key, value]) => `--${key}: ${value};`)
      .join('');

    document.head.insertAdjacentHTML('beforeend', `<style>:root{${cssVariables}</style>`);
  };

  useEffect(() => {
    setThemeVariables();
  }, []);

  const setupDocumentLang = () => {
    const dir = i18n.dir(i18n.resolvedLanguage);
    setDir(dir);

    document.body.setAttribute('lang', i18n.resolvedLanguage || '');
    document.body.setAttribute('dir', dir);
  };

  useEffect(() => {
    if (i18n.resolvedLanguage === currentLang.current) return;
    currentLang.current = i18n.resolvedLanguage;

    switch (i18n.resolvedLanguage) {
      case 'ar':
        import('dayjs/locale/ar')
          .then(() => import('antd/lib/locale/ar_EG'))
          .then((m) => {
            setAntDesignLocal(m.default);
            dayjs.locale('ar');
          });
        break;
      case 'en':
        import('antd/lib/locale/en_US').then((m) => {
          setAntDesignLocal(m.default);
          dayjs.locale('en');
        });
        break;
    }

    setupDocumentLang();
  }, [i18n.resolvedLanguage]);

  return {antDesignLocal, dir, themeTokens: defaultTheme};
}
