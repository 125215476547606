// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* antd tokens */
/* Media queries breakpoints */
/* auxiliary colors */
/* sizes */
.notificationsList___Rsdmh {
  height: 360px;
  overflow-x: auto;
  max-width: 420px;
}
.notificationsMenu___y62Ow {
  min-width: 300px;
}
.notificationsHeader___MtRAY {
  color: var(--colorPrimary);
  border-bottom: 1px solid var(--colorBorder);
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
}
.notification___bE0WE {
  border-bottom: 1px solid var(--colorBorder);
  padding: 10px 20px;
  border-left: 3px solid white;
}
.notification___bE0WE .notificationActions___Eq9Ri {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
[dir='rtl'] .notification___bE0WE {
  border-left: none;
  border-right: 3px solid white;
}
.newNotification___bVfPi {
  border-left: 3px solid var(--colorPrimary);
}
[dir='rtl'] .newNotification___bVfPi {
  border-left: none;
  border-right: 3px solid var(--colorPrimary);
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/AppTopBar/Notifications/styles.module.less"],"names":[],"mappings":"AAAA,gBAAgB;AAChB,8BAA8B;AAC9B,qBAAqB;AACrB,UAAU;AADV;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;AAGF;AAAA;EACE,gBAAA;AAEF;AACA;EACE,0BAAA;EACA,2CAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AACF;AAEA;EACE,2CAAA;EACA,kBAAA;EACA,4BAAA;AAAF;AAHA;EAMI,aAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAIA;EACE,iBAAA;EACA,6BAAA;AAFF;AAMA;EACE,0CAAA;AAJF;AAOA;EACE,iBAAA;EACA,2CAAA;AALF","sourcesContent":["@import '~theme/variable';\n\n.notificationsList{\n  height: 360px;\n  overflow-x: auto;\n  max-width: 420px;\n}\n\n.notificationsMenu{\n  min-width: 300px;\n}\n\n.notificationsHeader{\n  color: @color-primary;\n  border-bottom: 1px solid @color-border;\n  padding: 10px 20px;\n  font-weight: 600;\n  font-size: 16px;\n}\n\n.notification{\n  border-bottom: 1px solid @color-border;\n  padding: 10px 20px;\n  border-left: 3px solid white;\n\n  .notificationActions{\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n  }\n}\n\n[dir='rtl'] .notification{\n  border-left: none;\n  border-right: 3px solid white;\n}\n\n\n.newNotification{\n  border-left: 3px solid @color-primary;\n}\n\n[dir='rtl'] .newNotification{\n  border-left: none;\n  border-right: 3px solid @color-primary;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationsList": `notificationsList___Rsdmh`,
	"notificationsMenu": `notificationsMenu___y62Ow`,
	"notificationsHeader": `notificationsHeader___MtRAY`,
	"notification": `notification___bE0WE`,
	"notificationActions": `notificationActions___Eq9Ri`,
	"newNotification": `newNotification___bVfPi`
};
export default ___CSS_LOADER_EXPORT___;
