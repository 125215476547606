import React from 'react';

import {Spin} from 'antd';

export function Loader() {
  return <Spin />;
}

export function ContentLoader() {
  return (
    <div className="width-fill flex center-content section-padding-2">
      <Loader />
    </div>
  );
}
