import React from 'react';

import {Loader} from './Loader';

export function PageFallback(): JSX.Element {
  return (
    <div className="fill-window flex center-content">
      <Loader />
    </div>
  );
}
