import React from 'react';

import {Trans} from 'react-i18next';

import {IconView} from '@medad-sep/core';
import {MenuItem} from '@shared/models/MenuItem';

const adminItems: MenuItem[] = [
  {label: <Trans i18nKey="designer" />, route: `/admin/designer`, icon: <IconView icon="Brush" size={24} />},
  {
    label: <Trans i18nKey="configurations" />,
    route: `/admin/configurations`,
    icon: <IconView icon="Settings" size={24} />,
  },
];

export const menuItemsByRole: {[key: string]: MenuItem[]} = {
  admin: adminItems,
};

export const homeRouteByRole: {[key: string]: string} = {
  admin: 'admin',
};
