import React, {useEffect} from 'react';

declare const APP_VERSION: string;

export const AppVersion = () => {
  useEffect(() => {
    (window as any).version = APP_VERSION;
  }, []);

  return <meta name="ui-version" content={APP_VERSION} />;
};
